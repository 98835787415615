import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-healthcare-services',
    templateUrl: './healthcare-services.component.html',
    styleUrls: ['./healthcare-services.component.scss']
})
export class HealthcareServicesComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
