import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-education-services',
    templateUrl: './education-services.component.html',
    styleUrls: ['./education-services.component.scss']
})
export class EducationServicesComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
