<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white; margin-top: -4%">Terms And Conditions</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->
<section class="py-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <div class="card shadow" style="border: none!important;">
                    <div class="card-body">
                        <p>Welcome to the MS Group of Companies. These Terms & Conditions govern your use
                            of our website, and by accessing or using our website, you agree to comply with
                            these Terms & Conditions. Please read them carefully.</p>
                        <div class="features-text">
                            <h5> Acceptance of Terms</h5>
                            <p>By visiting MS Group, you acknowledge that you have read, understood, and agreed
                                to be bound by these Terms & Conditions, as well as our Privacy Policy. Please do
                                not use our website if you disagree with any aspect of these terms.</p>
                            <div class="features-text">
                                <h5>Use of Website</h5>
                                <p>You may use our website for non-commercial or personal purposes only. You agree
                                    not to:</p>
                                <ul class="features-text">
                                    <li class="color-for-list">
                                        Without MS Group of Companies’ prior written approval, you may not copy,
                                        reproduce, edit, distribute, display, perform, or adapt the material of our
                                        website.
                                    </li>
                                    <li class="color-for-list">
                                        It is not permitted to use our website for any illegitimate or unauthorized
                                        purposes
                                    </li>
                                    <li class="color-for-list">
                                        Use a false name or other form of identification, or otherwise misrepresent
                                        your association with a person or organization.
                                    </li>
                                    <li class="color-for-list">
                                        Use hacking, password mining, or any other method to attempt to gain
                                        unauthorized access to any area of our website, or any systems or networks
                                        connected to our website.
                                    </li>
                                    <li class="color-for-list">
                                        Disrupt or interfere with the operation of our servers or website, or violate
                                        any rules, regulations, or procedures of networks connected to our website.
                                    </li>
                                    <li class="color-for-list">
                                        Act   in   any   manner   that   could   damage,   disable,   overload,   or   otherwise
                                        negatively affect our website, or that could prevent any other party from
                                        using and enjoying it.
                                    </li>
                                    <li class="color-for-list">
                                        Upload or send any viruses, worms, or other harmful code that could disrupt
                                        the functionality of our website.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="features-text">
                            <h5> Intellectual Property Rights:</h5>
                            <p>All information on the MS Group website, such as text, graphics, pictures, logos,
                                audio and video clips, data compilations, software, and other materials, is the
                                property of MS Group or its licensors and is protected by applicable copyright,
                                trademark, and other intellectual property laws. Use/ misuse of any content without
                                written permission or acknowledgment is strictly prohibited.</p>
                        </div>
                        <div class="features-text">
                            <h5> Privacy</h5>
                            <p>Your privacy is crucial for us and we will not share, sell, or deficit for personal
                                information at any cost. Please review our Privacy Policy to know more about how
                                we use or collect personal data.  </p>
                        </div>
                        <div class="features-text">
                            <h5>External Links</h5>
                            <p>Links to third-party websites that are not under the ownership or control of MS
                                Group   of   Companies   may   appear   on   our   website.   Any   connected   third-party
                                websites' content, rules, or procedures are not our responsibility. Any third-party
                                websites that you visit through our portal should have terms and conditions and
                                privacy policies that you should evaluate.</p>
                        </div>
                        <div class="features-text">
                            <h5>Limitation of Liability</h5>
                            <p>Any direct, indirect, incidental, consequential, or exemplary damages, including but
                                not limited to loss of data or profits, arising out of or in connection with your use of
                                our website will not be covered by MS Group India or any of its affiliates, officers,
                                directors, employees, agents, or contractors.</p>
                        </div>
                        <div class="features-text">
                            <h5>Governing Law and Jurisdiction</h5>
                            <p>The laws of India shall apply to the interpretation and application of these Terms &
                                Conditions. The Delhi courts will have sole jurisdiction over any dispute arising out
                                of or related to these Terms & Conditions.</p>
                            <p>
                                If you have any questions or concerns about these Terms & Conditions, please
                                contact us at <a href="mailto:hr@msgroupindia.com">hr@msgroupindia.com</a>
                            </p>
                        </div>
                        <div class="features-text">
                            <h5>Modifications to Terms & Conditions</h5>
                            <p>These Terms & Conditions are subject to change at any moment and without
                                previous notice by MS Group of Companies. Any modifications will take effect as
                                soon as they are posted on our website as revised Terms & Conditions. You are
                                deemed to have accepted the updated Terms & Conditions if you continue to use
                                our website following any changes.</p>
                        </div>
                        <div class="features-text">
                            <h5>Disclaimer of Warranties</h5>
                            <p>Our website is provided "as-is" and "as available" without any express or implied
                                warranties of any kind. The uninterrupted, error-free, secure, or virus- or other
                                dangerous component-free operation of our website is not guaranteed by MS Group
                                India. We disclaim all explicit and implied warranties, including but not limited to
                                those of merchantability, fitness for a particular purpose, and non-infringement.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
