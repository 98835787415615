import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get API_URL(): string {
    return environment.appUrl + 'api/';

  }

  static FILE_UPLOAD(): string {
    return AppUrl.API_URL + 'file-upload';
  }


  static CAREER(id?): string {
    if (id) {
      return AppUrl.API_URL + 'career/' + id;
    } else {
      return AppUrl.API_URL + 'career';
    }
  }

  /*static CAREER(): string {
    return AppUrl.UNIVERSITY_URL + 'career';
  }*/

  static CAREER_COUNT(): string {
    return AppUrl.API_URL + 'career-count';
  }

  static CAREER_DETAILS(id): string {
    return AppUrl.API_URL + 'career/' + id;
  }

  static CAREER_STATUS(id): string {
    return AppUrl.API_URL + 'career-status/' + id;
  }

    static CONTACT_US(id?): string {
        if (id) {
            return AppUrl.API_URL + 'contact-us/' + id;
        } else {
            return AppUrl.API_URL + 'contact-us';
        }
    }

    /*static CONTACT_US(): string {
      return AppUrl.UNIVERSITY_URL + 'contact-us';
    }*/

    static CONTACT_US_COUNT(): string {
        return AppUrl.API_URL + 'contact-us-count';
    }

    static CONTACT_US_DETAILS(id): string {
        return AppUrl.API_URL + 'contact-us/' + id;
    }

    static CONTACT_US_STATUS(id): string {
        return AppUrl.API_URL + 'contact-us-status/' + id;
    }

}
