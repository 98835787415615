import {Component, OnInit} from '@angular/core';


@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    // isShowDiv = true;
    selectedTab = 1;


    constructor() {
    }

    ngOnInit(): void {
    }

    /*   toggleDisplayDiv() {
           this.isShowDiv = !this.isShowDiv;
       }*/
    selectTab(tabNumber: number) {
        this.selectedTab = tabNumber;
    }


    /*jqury*/


}
