<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="modalType === 'SUBMIT_FORM'"> Fill the form to apply for Position </h4>
        <h4 class="modal-title" *ngIf="modalType==='SUCCESS'"><i class="fa fa-check-square text-success"></i> Success
        </h4>
        <h4 class="modal-title text-capitalize">
            <!--<span *ngIf="selectedType ==='CENTER'"></span>-->
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </h4>
    </div>
    <div class="modal-body" *ngIf="modalType === 'SUBMIT_FORM'">
        <div class="row">
            <div class="col-sm-12">
                <form class="theme-form" [formGroup]="careerForm" enctype="multipart/form-data">
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="name">Name</label>
                        <input id="name" class="form-control " placeholder="Enter Name" formControlName="name" required>
                        <small *ngIf="submitted && careerForm.get('name').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="email">Email</label>
                        <input id="email" class="form-control " placeholder="Enter email" formControlName="email" type="email" required
                        >
                        <small *ngIf="submitted && careerForm.get('email').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                        <small *ngIf="submitted && careerForm.controls['email'].errors && careerForm.controls['email'].errors.pattern"
                               class="text-danger">Invalid email format</small>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="contact">Contact</label>
                        <input id="contact" class="form-control " placeholder="Enter Contact Number"
                               formControlName="contact">
                        <small *ngIf="submitted && careerForm.get('contact').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                        <small *ngIf="submitted && careerForm.controls['contact'].errors && careerForm.controls['contact'].errors.pattern"
                               class="text-danger">Invalid contact format</small>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="father_name">Experience</label>
                        <input id="father_name" class="form-control " placeholder="Enter Total Experience"
                               formControlName="experience">
                        <small *ngIf="submitted && careerForm.get('experience').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="form-group mt-10">
                        <button class="btn btn-primary pull-left mt-5px" id="post_graduation_certificate"
                                (click)="fileUploadLectureNotes.click()">
                            <i class="fas fa-upload mr-5px"></i>Upload Resume
                        </button>
                        <input class="form-control-file display-hidden" id="resume_upload"
                               type="file"
                               (change)="onFileChange($event, 'MSGROUP_RESUME_DOC')"
                               #fileUploadLectureNotes>
                        <span class="upload-btn-span-view cursor-pointer pull-left mt-5px"
                              (click)="goTo(fileUploadResumeDoc.url)" *ngIf="fileUploadResumeDoc">View</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-body" *ngIf="modalType === 'SUCCESS'">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="text-success">You have been successfully applied for position</h4>
                <h6 class="text-muted">Our Team will approach you for next step</h6>
            </div>
        </div>
    </div>
    <div class="modal-footer d-lg-block">
        <button class="btn btn-success mr-1 btn-pill pull-right"
                (click)="submit()"
                *ngIf="modalType === 'SUBMIT_FORM'">
            <i class="fa fa-edit ml-1"></i> Submit
        </button>
        <button class="btn btn-danger mr-1 btn-pill pull-left" (click)="modalService.dismissAll('')"><i
            class="fa fa-x ml-1"></i> Cancel
        </button>
    </div>
</ng-template>
<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1"
     style="background-image: url('assets/img/about/about-banner.png'); background-size: cover">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="margin-top: -3%; color: white;">{{selectedJob?.title}}</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!--job detail card section start here-->
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="card px-4  my-3 ">
                <h3 class="major-color pt-4">Job Description</h3>
                <p>
                    {{selectedJob?.job_description}}
                </p>

                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-4 col-sm-12 my-2 text-center">
                        <button class="btn  btn-lg px-3 py-2 h-100" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; cursor: default;">
                            <img src="assets/img/icon/location_icon.png" alt="" class="pe-2"> <span class="major-color">Delhi, India</span>
                        </button>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 my-2 text-center">
                        <button class="btn  btn-lg px-3  h-100" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; cursor: default;">
                            <img src="assets/img/icon/education_icon.png" alt="" class="pe-2"> <span class="major-color">Any Graduate</span>
                        </button>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 my-2 text-center">
                        <button class="btn  btn-lg px-3 py-2 h-100" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; cursor: default;">
                            <img src="assets/img/icon/experience_icon.png" alt="" class="pe-2"> <span class="major-color">0-10 Years</span>
                        </button>
                    </div>
                </div>
                <div class="my-5 check">
                    <h3 class="major-color">Job Responsibilities</h3>
                    <p>
                        {{selectedJob?.job_responsibility_data}}
                    </p>
                    <span class="text-muted"  [innerHTML]="selectedJob?.job_responsibility">

                    </span>

                </div>

                <div class="">
                    <h3 class="major-color">Required Skill</h3>
                    <div *ngFor="let skill of selectedJob?.skills_required" >
                        <p><img src="assets/img/icon/tick_icon.png" class="px-3" alt="dsvdsv"> {{skill}}
                        </p>

                    </div>

                </div>

                <div class="row my-5">
                    <div class="col-12 text-center">
                        <button class="btn btn-lg btn-light pt-2" (click)="openApplyModal(content)"
                                style="box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
                            <a class="read-more new-btn-25252">
                                Apply Here &nbsp; <span class="float-end"><img
                                src="assets/img/machine-learning/pseudo.png" alt=""></span>
                            </a></button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!--job details card section end here-->

