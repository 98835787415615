<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white; margin-top: -4%">Services</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<section class="why-choose-area ptb-100">
    <div class="container">
        <h2 style="color: blue;">
            <span class="sub-title major-color ">Why <span class="cl-blue">Choose Us</span></span>
        </h2>
        <div class="row my-3">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-content">
                    <!--<span class="sub-title">Why Choose Us</span>-->
                    <h2>Enabling Growth Through Innovation & Development</h2>
                    <p>Innovation is an essential driver of our progress that benefits our consumers, businesses,
                        partners and the economy as a whole. Our constant hardwork describes the development and
                        application of ideas and technologies that improve our goods and services.</p>
                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Managing Brand Over Time</h4>
                        <p>From last 22 years, MS Group is managing all its brands well and successfully. A
                            trustworthy name in all the sectors it has entered so far.</p>
                    </div>

                    <div class="features-text">
                        <h4><i class="flaticon-tick"></i> Small Steps Towards Transition</h4>
                        <p>We have got big goals in mind, taking each step wisely, we have created a niche in our
                            every field, that you can trust upon. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-image">
                    <img src="assets/img/why-choose-img1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Start Why Choose Us Area -->
<section class="why-choose-area ptb-100">
    <div class="tab-pane active">
        <h2 style="text-align: center; color: blue;"><span class="sub-title major-color">Our <span class="cl-blue">Companies</span></span></h2>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <a href="https://msinstituteindia.com/" target="_blank">   <img src="assets/img/projects-image/products/ms_institute.png" class="img-fluid work-image" alt=""></a>
                        <div class="card-body">
                            <div class="content">
                                <!--<a href="programs/ba.html" class="badge badge-link bg-success"
                                   style="background-color: #0a53be !important;"></a>-->
                                <h5 class="mt-3"><!--<a href="programs/ba.html" class="text-dark title"></a>-->
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://msinstituteindia.com/" target="_blank">MS Institute of
                                        Education</a></h5>
                                    <span class="category">Education</span>
                                </div>
                                <!-- <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>-->
                                <!-- <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <a href="https://educationmitra.in/" target="_blank">    <img src="assets/img/projects-image/products/education_mitra.png" class="img-fluid work-image" alt=""></a>
                        <div class="card-body">
                            <div class="content">
                                <!-- <a href="programs/ba.html" class="badge badge-link bg-success"
                                    style="background-color: #0a53be !important;"></a>-->
                                <h5 class="mt-3"><!--<a href="programs/ba.html" class="text-dark title"></a>-->
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://educationmitra.in/" target="_blank">Education Mitra</a></h5>
                                    <span class="category">Education</span>
                                </div>
                                <!-- <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>-->
                                <!-- <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <a href="https://edutantra.in/" target="_blank">  <img src="assets/img/projects-image/products/edutantra.png" class="img-fluid work-image" alt=""></a>
                        <div class="card-body">
                            <div class="content">
                                <!-- <a href="programs/ba.html" class="badge badge-link bg-success"
                                    style="background-color: #0a53be !important;"></a>-->
                                <h5 class="mt-3"><!--<a href="programs/ba.html" class="text-dark title"></a>-->
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://edutantra.in/" target="_blank">Edutantra</a></h5>
                                    <span class="category">Education</span>
                                </div>
                                <!-- <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>-->
                                <!-- <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <a href="https://draxico.com/" target="_blank"><img src="assets/img/projects-image/products/dr_axico.png" class="img-fluid work-image" alt=""></a>
                        <div class="card-body">
                            <div class="content">
                                <!-- <a href="programs/ba.html" class="badge badge-link bg-success"
                                    style="background-color: #0a53be !important;"></a>-->
                                <h5 class="mt-3"><!--<a href="programs/ba.html" class="text-dark title"></a>-->
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://draxico.com/" target="_blank">Dr Axico</a></h5>
                                    <span class="category">Healtcare</span>
                                </div>
                                <!-- <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>-->
                                <!-- <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <a href="https://axicoayurveda.com/" target="_blank"> <img src="assets/img/projects-image/products/axico_ayurveda.png" class="img-fluid work-image" alt=""></a>
                        <div class="card-body">
                            <div class="content">
                                <!-- <a href="programs/ba.html" class="badge badge-link bg-success"
                                    style="background-color: #0a53be !important;"></a>-->
                                <h5 class="mt-3"><!--<a href="programs/ba.html" class="text-dark title"></a>-->
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://axicoayurveda.com/" target="_blank">Axico Ayurveda</a></h5>
                                    <span class="category">Healthcare</span>
                                </div>
                                <!-- <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>-->
                                <!-- <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>
               <!-- <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <img src="assets/img/projects-image/projects4.png" class="img-fluid work-image" alt="">
                        <div class="card-body">
                            <div class="content">
                                &lt;!&ndash; <a href="programs/ba.html" class="badge badge-link bg-success"
                                    style="background-color: #0a53be !important;"></a>&ndash;&gt;
                                <h5 class="mt-3">&lt;!&ndash;<a href="programs/ba.html" class="text-dark title"></a>&ndash;&gt;
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://axicoinstitute.com/" target="_blank">Axico Institute</a></h5>
                                    <span class="category">Education</span>
                                </div>
                                &lt;!&ndash; <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>&ndash;&gt;
                                &lt;!&ndash; <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>&ndash;&gt;
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <a href="https://nationliveiptv.com/" target="_blank">  <img src="assets/img/projects-image/products/news_nation.png" class="img-fluid work-image" alt=""></a>
                        <div class="card-body">
                            <div class="content">
                                <!-- <a href="programs/ba.html" class="badge badge-link bg-success"
                                    style="background-color: #0a53be !important;"></a>-->
                                <h5 class="mt-3"><!--<a href="programs/ba.html" class="text-dark title"></a>-->
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://nationliveiptv.com/" target="_blank">Nation Live</a></h5>
                                    <span class="category">Media & Marketing</span>
                                </div>
                                <!-- <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>-->
                                <!-- <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item">
                    <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                        <a href="https://www.sbprakashan.com/" target="_blank">  <img src="assets/img/projects-image/products/sb_prakashan.png" class="img-fluid work-image" alt=""></a>
                        <div class="card-body">
                            <div class="content">
                                <!-- <a href="programs/ba.html" class="badge badge-link bg-success"
                                    style="background-color: #0a53be !important;"></a>-->
                                <h5 class="mt-3"><!--<a href="programs/ba.html" class="text-dark title"></a>-->
                                </h5>
                                <div class="projects-content" style="text-align: center;">
                                    <h5><a href="https://www.sbprakashan.com/" target="_blank">S.B Prakashan</a></h5>
                                    <span class="category">Publishing House</span>
                                </div>
                                <!-- <p class="text-muted">It’s an undergraduate course, and most of the students
                                     pursue
                                     it
                                     after successful completion of their 12th class examination.</p>-->
                                <!-- <a href="programs/ba.html" class="text-primary h6">Read More <i
                                     class="uil uil-angle-right-b align-middle"></i></a>-->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->

<!-- Start Services Area -->
<!--<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">What We Do</span>
            <h2>Create awesome life changing products</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing"></i>
                    </div>
                    <h3><a routerLink="/service-details">Digital Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-00aeff">
                        <i class="flaticon-research"></i>
                    </div>
                    <h3><a routerLink="/service-details">Design & Development</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3><a routerLink="/service-details">Strategic Planing</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3><a routerLink="/service-details">SEO Consultancy</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a routerLink="/service-details">Competitor Analysis</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-seo"></i>
                    </div>
                    <h3><a routerLink="/service-details">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3><a routerLink="/service-details">Marketing Analysis</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a routerLink="/service-details">Email Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-mail"></i>
                    </div>
                    <h3><a routerLink="/service-details">Website Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet elit sed, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt.</p>
                    <a routerLink="/service-details" class="read-more-btn">Read More <i
                        class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Services Area -->
