import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-media-and-marketing-services',
    templateUrl: './media-and-marketing-services.component.html',
    styleUrls: ['./media-and-marketing-services.component.scss']
})
export class MediaAndMarketingServicesComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
