<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="karlson-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo" style="max-width: 160px !important;"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a routerLink="/about" routerLinkActive="active" class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a routerLink="/services" routerLinkActive="active" class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a routerLink="/blogs" routerLinkActive="active"  class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false" >

                                Blogs
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/careers" routerLinkActive="active">Careers</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
