<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white;">Publishing House Services</h2>
                    <ul>
                        <li style="color: white;">Publishing House Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Publishing House Services</h3>
                <p>Our Publishing house publishes standardized and quality content textbooks for the students of class
                    KG to 8th and study learning materials for university’s UG/PG programs.</p>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> S.B. PRAKASHAN Pvt. Ltd.</h4>
                    <p>Welcome to the World of Book Publishing!!<br>
                        Company's vision is to provide wholesome education using print and e-media to nurture quality
                        minds for the country's human resource. A leading name in higher education publishing,
                        specializes in the publication of academic and reference books in various Online/Distance
                        Undergraduate / Postgraduate learning programs. We also work as a publishing house for various
                        school textbooks and presently deal with content related to classes 1st to 8th.</p>
                    <p>
                        Each year, we are growing as a company. We are making our name in one of the leading publication
                        houses in the current market. Our books go through a careful examination and it is made sure
                        that only standardized and quality content is published in our textbooks to provide students
                        with the best of knowledge. We have a prestigious batch of authors who work with top educational
                        institutes. We cover not only North India but also deal with central and south India and are
                        looking forward to expanding further. We aim to provide the best quality material, that is both,
                        knowledgeable and attractive.
                    </p>
                </div>
            </div>
            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <!--<img src="assets/img/about-img1.png" alt="image">-->
                <img src="assets/img/publish-min.png" alt="image" style="margin-left: 36px; max-width: 91%">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->
