<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fa fa-check-square text-success"></i> Message Sent</h4>
        <h4 class="modal-title text-capitalize">
            <!--<span *ngIf="selectedType ==='CENTER'"></span>-->
            <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll('close')">
                x
            </button>
        </h4>

    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="text-success">Your Query has been successfully sent</h4>
                <h6 class="text-muted">Our Team will approach you!</h6>
            </div>
        </div>
    </div>
    <div class="modal-footer d-lg-block">
        <button class="btn btn-danger mr-1 btn-pill pull-left" (click)="modalService.dismissAll('close')" ><i
            class="fa fa-x ml-1"></i> Cancel
        </button>
    </div>
</ng-template>
<!-- Start Page Title Area -->
<!--start careers banner section-->
<div class="container-fluid">
    <div class="row">
        <div class="col-12 position-relative" style="padding-left: 0px;">
            <img src="assets/img/career/career_banner.png" class="" alt="carrer banner " style="width: 100%; height: auto">
        </div>
        <div class="position-absolute  career-main-banner">
            <div class="mx-lg-5 mx-md-5  mt-lg-5 mt-md-5 mt-5">
                <h1 class="text-white ">Be a Part of <span class="cl-blue">MS Group Of Companies</span></h1>
            </div>
            <div class="mx-5 mt-lg-5 mt-md-0 mt-sm-0 d-none d-lg-block d-md-block" >
                <p class="text-white">
                    We’re searching for innovative thinkers to collaborate and grow in the creative technology <br>
                    space.
                </p>
                <p class="text-white" >
                    There is no blueprint for what we’re trying to accomplish. We give our team members the <br> autonomy to
                    try new things and achieve their goals. At MS Group Of Companies you’ll have the <br> resources and
                    support you need to advance your career and industry knowledge.
                </p>
            </div>

        </div>
    </div>
</div>
<!--End careers banner section-->

<!--start our team section-->
<div class="container-fluid">
    <div class="row mt-4">
        <div class="col-12 text-center my-4">
            <h1 class="major-color">
                Our <span class="cl-blue">Teams</span>
            </h1>
            <p>
                At our company, we believe that teams are the backbone of success and innovation. Our career page is a
                testament to the value we <br> place on collaboration, diversity, and collective growth.
            </p>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/it.png" alt="">
            <h5 class="major-color pt-4">Information Technology</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/finance.png" alt="">
            <h5 class="major-color pt-4">Finance</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/news.png" alt="">
            <h5 class="major-color pt-4">News</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/designing.png" alt="">
            <h5 class="major-color pt-4">Designing</h5>
        </div>
    </div>
    <div class="row mt-5 text-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/customer_service.png" alt="">
            <h5 class="major-color pt-4">Customer Services</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/consultation.png" alt="">
            <h5 class="major-color pt-4">Consultations</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/sales.png" alt="">
            <h5 class="major-color pt-4">Sales</h5>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <img src="assets/img/team/marketing.png" alt="">
            <h5 class="major-color pt-4">Marketing</h5>
        </div>
    </div>
</div>
<!--End our team section-->

<!--start benefits of joining us section-->
<div class="container" style="margin-top: 10rem">
    <div class="row my-5 align-items-center">
        <div class="col-lg-7 col-md-6 col-sm-12">
            <img src="assets/img/career/career.png"  alt="career image">
        </div>
        <div class="col-lg-5 col-md-6 col-sm-12">
            <div class="row">
                <div class="col-12">
                    <h1 class="major-color ps-4">Benefits Of <span class="cl-blue">Joining Us</span></h1>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ul style="list-style-type: none">
                        <li><img src="assets/img/icon/tick_logo.png" class="pe-3 py-3" alt="point"> Professional Growth
                        </li>
                        <li><img src="assets/img/icon/tick_logo.png" class="pe-3 py-3" alt="point"> Innovative
                            Environment
                        </li>
                        <li><img src="assets/img/icon/tick_logo.png" class="pe-3 py-3" alt="point"> Collaborative
                            Culture
                        </li>
                        <li><img src="assets/img/icon/tick_logo.png" class="pe-3 py-3" alt="point"> Flexibility & Work
                            Life Balance
                        </li>
                        <li><img src="assets/img/icon/tick_logo.png" class="pe-3 py-3" alt="point"> Inclusive and
                            Diverse Workplace
                        </li>
                        <li><img src="assets/img/icon/tick_logo.png" class="pe-3 py-3" alt="point"> Recognition &
                            Rewards
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12 p-5">
                    <button class="btn btn-light pt-2 p" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"><a
                        routerLink="/education-services" class="read-more new-btn-25252">
                        Know More &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
                    </a></button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--End benefits of joining us section-->


<!--<div class="page-title-area page-title-bg1" style="background-image: url('assets/img/cover.jpg');">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white; margin-top: -4%;">Careers</h2>
                    &lt;!&ndash;   <ul>
                           <li><a routerLink="/">Home</a></li>
                           <li>Blog Grid</li>
                       </ul>&ndash;&gt;
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>-->
<!--<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="modalType === 'SUBMIT_FORM'"> Fill the form to apply for Position </h4>
        <h4 class="modal-title" *ngIf="modalType==='SUCCESS'"><i class="fa fa-check-square text-success"></i> Success
        </h4>
        <h4 class="modal-title text-capitalize">
            &lt;!&ndash;<span *ngIf="selectedType ==='CENTER'"></span>&ndash;&gt;
            <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll('')">
                <span aria-hidden="true">&times;</span>
            </button>
        </h4>
    </div>
    <div class="modal-body" *ngIf="modalType === 'SUBMIT_FORM'">
        <div class="row">
            <div class="col-sm-12">
                <form class="theme-form" [formGroup]="careerForm" enctype="multipart/form-data">
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="name">Name</label>
                        <input id="name" class="form-control " placeholder="Enter Name" formControlName="name" required>
                        <small *ngIf="submitted && careerForm.get('name').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="email">Email</label>
                        <input id="email" class="form-control " placeholder="Enter email" formControlName="email"
                        >
                        <small *ngIf="submitted && careerForm.get('email').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="contact">Contact</label>
                        <input id="contact" class="form-control " placeholder="Enter Contact Number"
                               formControlName="contact">
                        <small *ngIf="submitted && careerForm.get('contact').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label pt-0 mt-10" for="father_name">Experience</label>
                        <input id="father_name" class="form-control " placeholder="Enter Total Experience"
                               formControlName="experience">
                        <small *ngIf="submitted && careerForm.get('experience').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="form-group mt-10">
                        <button class="btn btn-primary pull-left mt-5px" id="post_graduation_certificate"
                                (click)="fileUploadLectureNotes.click()">
                            <i class="fas fa-upload mr-5px"></i>Upload Resume
                        </button>
                        <input class="form-control-file display-hidden" id="resume_upload"
                               type="file"
                               (change)="onFileChange($event, 'RESUME_DOC')"
                               #fileUploadLectureNotes>
                        <span class="upload-btn-span-view cursor-pointer pull-left mt-5px"
                              (click)="goTo(fileUploadResumeDoc.url)" *ngIf="fileUploadResumeDoc">View</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-body" *ngIf="modalType === 'SUCCESS'">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="text-success">You have been successfully applied for position</h4>
                <h6 class="text-muted">Our Team will approach you for next step</h6>
            </div>
        </div>
    </div>
    <div class="modal-footer d-lg-block">
        <button class="btn btn-success mr-1 btn-pill pull-right"
                (click)="submit()"
                *ngIf="modalType === 'SUBMIT_FORM'">
            <i class="fa fa-edit ml-1"></i> Submit
        </button>
        <button class="btn btn-danger mr-1 btn-pill pull-left" (click)="modalService.dismissAll('')"><i
            class="fa fa-x ml-1"></i> Cancel
        </button>
    </div>
</ng-template>-->

<!--careers section start here-->
<div class="container my-4">
    <div class="row">
        <div class="col-12 text-center my-3">
            <h1 class="major-color">
                Career <span class="cl-blue">Opportunities</span>
            </h1>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let job of jobOpenings">
            <div class="card mb-4 shadow" style="border: none;">
                <div class="card-body">
                    <div class="">
                        <button class="btn  career-type-btn p-2 " style="cursor:default">
                            {{job?.area}}
                        </button>
                        <span
                            class="cl-blue"> ---------------------------</span>
                    </div>
                    <h4 class="major-color py-3">{{job?.title}}</h4>
                    <div class="d-flex">
                        <img src="assets/img/icon/location_icon.png" alt="location">
                        <p class="ps-3">New, Delhi</p>
                    </div>
                    <div class="my-4">
                        <button class="btn career-ofc-btn p-2" style="cursor:default">
                            ◉ <span class="px-2">Full Time</span>
                        </button>
                        <button class="btn career-ofc-btn p-2 mx-3" style="cursor:default">
                            ◉ <span class="px-2">Office</span>
                        </button>
                    </div>
                    <div class="d-grid ">
                        <button class="btn btn-lg btn-light pt-2"  (click)="goto(job)"
                                style="box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
                            <a class="read-more new-btn-25252">
                                Apply Here &nbsp; <span class="float-end"><img
                                src="assets/img/machine-learning/pseudo.png" alt=""></span>
                            </a></button>
                        <!--                        <button class="btn btn-primary" type="button">Button</button>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!--careers section end here-->


<!--<div class="container mt-4 mb-4">
    <div class="row">
        <div class="col-md-6" *ngFor="let job of jobOpenings">
            <div class="card mb-4 shadow" style="border: none;min-height: 403px;">
                <div class="card-body">
                    <div class=""><h4 style="margin-top: 15px; color: #3F4096">{{job?.title}}</h4></div>
                    <h5 class="card-title">Qualifications</h5>
                    <p class="card-text"><i
                        class="fa-solid fa-circle-check text-success mr-5px mt-1"></i> {{job?.qualification}}</p>
                    <h6 class="card-title">Technical Skills</h6>
                    <div class="card-text d-flex"><i class="fa-solid fa-circle-check text-success mr-5px mt-1"></i>
                        <p>{{job?.skills_required}}</p>
                    </div>
                    &lt;!&ndash;   <p class="card-text">frontend:-<br> <span class="ml-5">Angular</span><br> <span class="ml-5">JS</span><br><span class="ml-5">CSS</span><br><span class="ml-5">HTML</span><br><span class="ml-5">API</span></p>&ndash;&gt;
                    <h6 class="card-title">Years of experience: </h6>
                    <p class="card-text"><i
                        class="fa-solid fa-circle-check text-success mr-5px"></i> {{job?.years_of_experience}}</p>
                    <button class="card-link cursor-pointer  btn btn-primary" (click)="openApplyModal(content)">
                        Apply
                    </button>
                </div>
                &lt;!&ndash;   <div class="card">
                       <div class="card-footer">

                       </div>
                   </div>&ndash;&gt;
            </div>
        </div>
    </div>
</div>-->
<!--<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        This is example from ItSolutionStuff.com
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>-->

<!--sign up job alert form start here-->
<div class="container">
    <div class="row my-5">
        <div class="col-lg-7 col-md-12 col-sm-12">
            <h1 class="major-color">Sign Up For <span class="cl-blue">Job Alerts</span></h1>
            <p>
                Find roles that are just the right fit for you, delivered straight <br> to your inbox. The next
                opportunity
                you see could become <br> your new career.
            </p>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12">
            <form id="signUpForm" [formGroup]="signUpForm">
                <div class="row mb-4">
                    <div class="col">
                        <input type="text" name="name" id="name" class="form-control" placeholder="Name" formControlName="name">
                        <small *ngIf="submitted && signUpForm.get('name').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="col">
                        <input type="email" name="email" id="email" class="form-control" required
                               placeholder="Email" formControlName="email">
                        <small *ngIf="submitted && signUpForm.get('email').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                        <small *ngIf="submitted && signUpForm.controls['email'].errors && signUpForm.controls['email'].errors.pattern"
                               class="text-danger">Invalid email format</small>
                    </div>
                </div>
                <div class="row mb-4 ">
                    <div class="col">
                        <input type="tel" name="phone_number" id="phone_number" class="form-control" required
                               placeholder="Phone" formControlName="contact">
                        <small *ngIf="submitted && signUpForm.get('contact').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                        <small *ngIf="submitted && signUpForm.controls['contact'].errors && signUpForm.controls['contact'].errors.pattern"
                               class="text-danger">Invalid contact format</small>
                    </div>
                    <div class="col">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                               placeholder="Subject" formControlName="subject">
                        <small *ngIf="submitted && signUpForm.get('subject').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                </div>
                <div class="form-group mb-4">
                 <textarea name="message" class="form-control resize-none" id="message" cols="30" rows="5"
                           placeholder="Your Message" style="resize: none" formControlName="message"></textarea>
                </div>
                <div class="mt-5">
                    <button type="submit" class="btn btn-light pt-2" (click)="submit(content)" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;" ><a class="read-more new-btn-25252">
                        Submit &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
                    </a></button>
                </div>
            </form>
        </div>
    </div>
</div>
<!--sign up job alert form end here-->
