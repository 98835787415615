import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/layouts/preloader/preloader.component';
import {NavbarComponent} from './components/layouts/navbar/navbar.component';
import {SidebarComponent} from './components/layouts/sidebar/sidebar.component';
import {FooterComponent} from './components/layouts/footer/footer.component';
import {AboutComponent} from './components/pages/about/about.component';
import {ServicesComponent} from './components/pages/services/services.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {HomeComponent} from './components/pages/home/home.component';
import {EducationServicesComponent} from './components/pages/education-services/education-services.component';
import {HealthcareServicesComponent} from './components/pages/healthcare-services/healthcare-services.component';
import {MediaAndMarketingServicesComponent} from './components/pages/media-and-marketing-services/media-and-marketing-services.component';
import {PublishingHouseServicesComponent} from './components/pages/publishing-house-services/publishing-house-services.component';
import {CareersComponent} from './components/pages/careers/careers.component';
import {ReactiveFormsModule} from "@angular/forms";
import {HtpInterceptor} from "./shared/services/interceptor.service";
import {AlertService} from "./shared/services/alert.service";
import {GlobalService} from "./shared/services/global.service";
import {SnotifyService, ToastDefaults} from "ng-snotify";
import {HttpClientModule} from "@angular/common/http";
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import {OwlModule} from 'ngx-owl-carousel';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';





@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        AboutComponent,
        ServicesComponent,
        BlogComponent,
        NotFoundComponent,
        FaqComponent,
        ContactComponent,
        HomeComponent,
        EducationServicesComponent,
        HealthcareServicesComponent,
        MediaAndMarketingServicesComponent,
        PublishingHouseServicesComponent,
        CareersComponent,
        GalleryComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxScrollTopModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxImgZoomModule,
        OwlModule
    ],
    providers: [HtpInterceptor,
        GlobalService,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,],
    bootstrap: [AppComponent]
})
export class AppModule {
}
