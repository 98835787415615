<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white;">Media & Marketing Services</h2>
                    <ul>
                        <li style="color:white;">Media & Marketing Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Media & Marketing Services</h3>
                <p>This is one of our sustained effort to educate individuals and boost public awareness about
                    social-political reforms through most effective news content and other initiatives.</p>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Jan Media Publications Pvt. Ltd.</h4>
                    <p>We have a grand vision of becoming thought leads and translating thought to actions that create a
                        better brotherhood of man in the future. Established in 2011, Jan media Publications Pvt. Ltd.
                        is constantly creating a buzz around to make the social beings more informed and aware. National
                        Education Expo and Majesty Awards are some of its main initiatives.</p>
                </div>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Nation Live</h4>
                    <p>Nation live IPTV was established in 2018, this venture has grown with a steady speed and became
                        one of the leading player in Electronic (Digital) Media. Nation Live works to provide the latest
                        news and information to the Public at the fastest speed. Its news channel successfully runs on
                        YouTube. Nation live IPTV goes beyond the usual format and encourages feedbacks and comments,
                        making the channel truly interactive. It is one more expression of our society and
                        service-oriented way of thinking.</p>
                </div>
            </div>
            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <!--<img src="assets/img/about-img1.png" alt="image">-->
                <img src="assets/img/media-min.png" alt="image" style="margin-left: 36px; max-width: 91%">
            </div>
        </div>

        <!--<div class="services-details-overview">
            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/why-choose-img1.png" alt="image">
            </div>
            <div class="services-details-desc">
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> S.B. Prakashan Pvt. Ltd.</h4>
                    <p>Welcome to the World of Book Publishing!! <br>
                        A leading name in higher education publishing, specializes in the publication of academic and
                        reference books in various Online / Distance Undergraduate / Postgraduate learning programs. We
                        also work as a publishing house for various school textbooks and presently deal with content
                        related to classes 1st to 8th.</p>
                </div>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Education Mitra</h4>
                    <p>Education Mitra is the dedicated advanced user-friendly search engine platform that provides a
                        comprehensive database of various Open and Distance Learning / Online Learning
                        Undergraduate / Postgraduate programs, free online guidance, and counselling sessions (one to
                        one
                        live), and all the information regarding all the Higher Education Institutions which are
                        entitled to start Online / Distance programs from UGC.</p>
                </div>
            </div>
        </div>-->
    </div>
</section>
<!-- End Services Details Area -->
