<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white;">Education Services</h2>
                    <ul>
                        <li style="color: white;">Education Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Education Services</h3>
                <p>MS Group starts its journey in education sector by launching various educational programs like
                    Distance learning programs in 1997. Presently, we are catering our services to students all over the
                    nation with various academic, vocational or technical programs in order to help achieve their
                    desired goals.</p>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> MS Institute of Education</h4>
                    <p>Knowledge doesn’t discriminate. Having this as our motto, we thrive to provide education to all,
                        without letting distance being one of the barriers in this process. The Distance Educational
                        Programs, Regular and Online programs, facilitated by MS Institute, are done through
                        collaborations with various renowned Universities, under the norms of UGC and Ministry of HRD
                        from more than 15 years.</p>
                </div>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Edutantra</h4>
                    <p>We work as an Ed-tech company for professional and higher education by providing quality
                        education through our Bachelors, Masters Programs, Diplomas, Advance Diplomas and Post Graduate
                        Diplomas from the best Universities of India and abroad which are designed to give your career
                        the perfect boost to achieve your full potential.</p>
                </div>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/about-img1.jpg" alt="image" style="margin-left: 36px; max-width: 91%">
            </div>
        </div>

        <div class="services-details-overview">
            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/MS%20Group%20Web%20Images2-min.png" alt="image" style="max-width: 91%">
            </div>

            <div class="services-details-desc">
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Education Mitra</h4>
                    <p>Education Mitra is the dedicated advanced user-friendly search engine platform that provides a
                        comprehensive database of various Open and Distance Learning / Online Learning
                        Undergraduate / Postgraduate programs, free online guidance, and counselling sessions (one to
                        one
                        live), and all the information regarding all the Higher Education Institutions which are
                        entitled to start Online / Distance programs from UGC.</p>
                </div>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Axico Institute of Higher Education</h4>
                    <p>Axico Institute of Higher education is an official Authorised Enrolment Partner of NMIMS (Narsee Monjee
                        Institute of Management Studies), we provide support to the students enrolled through our
                        respective centre and assist & facilitate prospective students to take admissions in various
                        Certificates, Diploma and Distance MBA.</p>
                </div>
                <!--<div class="services-details-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Which material types can you work with?
                            </a>
                            <p class="accordion-content show">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Is Smart Lock required for instant apps?
                            </a>
                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                ultrices gravida.</p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Why Choose Our Services In Your Business?
                            </a>
                            <p class="accordion-content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                ultrices gravida.</p>
                        </li>
                    </ul>
                </div>-->
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->
