<!-- Start Page Title Area -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title"><i class="fa fa-check-square text-success"></i> Message Sent</h4>
        <h4 class="modal-title text-capitalize">
            <!--<span *ngIf="selectedType ==='CENTER'"></span>-->
            <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll('close')">
                x
            </button>
        </h4>

    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <h4 class="text-success">Your Query has been successfully sent</h4>
                <h6 class="text-muted">Our Team will approach you!</h6>
            </div>
        </div>
    </div>
    <div class="modal-footer d-lg-block">
        <button class="btn btn-danger mr-1 btn-pill pull-left" (click)="modalService.dismissAll('close')" ><i
            class="fa fa-x ml-1"></i> Cancel
        </button>
    </div>
</ng-template>
<div class="page-title-area page-title-bg1" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white; margin-top: -4%;">Contact</h2>
                  <!--  <ul>
                        &lt;!&ndash;<li><a routerLink="/">Home</a></li>&ndash;&gt;
                        <li style="color: white;">Contact</li>
                    </ul>-->
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ">
    <div class="container">
        <div class="row justify-content-center pt-5">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3>Email</h3>
                    <!--<p><a href="mailto:help@msgroupindia.com">help@msgroupindia.com</a></p>-->
                    <p style="padding-bottom: 28px"><a href="mailto: hr@msgroupindia.com"> hr@msgroupindia.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-marker"></i>
                    </div>
                    <h3>Location</h3>
                    <p>
                        <a href="https://goo.gl/maps/JS9CEEoJH3qjTBgr9" target="_blank">
                            WZ-6, Jail Rd, Lajwanti Garden, Mayapuri, New Delhi, Delhi 110046</a>
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-phone-call"></i>
                    </div>
                    <h3>Contact</h3>
                    <p><a href="tel:1800 891 8462" style="margin-bottom: 30px">1800 891 8462</a></p>
                    <!--<p><a href="tel:2414524526">+241 452 4526</a></p>-->
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-lg-12">
                <div class="section-title">
                    <span class="sub-title">Contact Us</span>
                    <h2>Drop us Message for any Query</h2>
                    <!--   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                           dolore magna aliqua.</p>-->
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="contact-image">
                            <img src="assets/img/contact.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="contact-form">
                            <form id="contactForm" [formGroup]="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Name" formControlName="name">
                                            <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control" required
                                                   placeholder="Email" formControlName="email">
                                            <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                            <small *ngIf="submitted && contactForm.controls['email'].errors && contactForm.controls['email'].errors.pattern"
                                                   class="text-danger">Invalid email format</small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="tel" name="phone_number" id="phone_number" class="form-control" required
                                                   placeholder="Phone" formControlName="contact">
                                            <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                            <small *ngIf="submitted && contactForm.controls['contact'].errors && contactForm.controls['contact'].errors.pattern"
                                                   class="text-danger">Invalid contact format</small>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                                   placeholder="Subject" formControlName="subject">
                                            <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                                                   class="text-danger">
                                                Field is required.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                    <textarea name="message" class="form-control resize-none" id="message" cols="30" rows="5"
                                              placeholder="Your Message" style="resize: none" formControlName="message"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center">
                                        <button type="submit" class="default-btn" (click)="submit(content)">Send Message <span></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row" style="margin-top: 100px">
            <div class="col-lg-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.5200919670315!2d77.10607711555033!3d28.614170582425007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1cab49555555%3A0x282cc4f85485f3b6!2sMS%20Group%20Of%20Companies!5e0!3m2!1sen!2sin!4v1651665354801!5m2!1sen!2sin"
                    style="border:0; width: 100%; height: 450px" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
