<!-- Start Main Banner Area -->
<div class="machine-learning-slider owl-carousel owl-theme">
    <div class="machine-learning-banner ml-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>Where Education & Technology Integrates</h1>
                                <p>Our passion of spreading education led us to be the most trusted Ed-Tech company
                                    where we not only provide online and distance learning programs but also the
                                    services of a publishing house.</p>
                                <!--<div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-4">
                                        Get Started <span></span>
                                    </a>
                                    <a routerLink="/" class="default-btn-two">
                                        Try It Free <span></span>
                                    </a>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="ml-video">
                                <div class="solution-video">
                                    <a href="https://www.youtube.com/watch?v=npYoAwQc4_k"
                                       class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ml-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>Health, Healing & Wellness</h1>
                                <p>The first wealth is health. Keeping this as our motto, we strive to become the
                                    globally recognized business organisation in the field of health solutions.</p>
                                <!--<div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-4">
                                        Get Started <span></span>
                                    </a>

                                    <a routerLink="/" class="default-btn-two">
                                        Try It Free <span></span>
                                    </a>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="ml-video">
                                <div class="solution-video">
                                    <a href="https://www.youtube.com/watch?v=OEHz4dywIKE"
                                       class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
    <div class="machine-learning-banner ml-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>Building Engagement & Social Transformation</h1>
                                <p>JanMedia plays a vital role in establishing and influencing social norms through
                                    unbiased information and effective marketing strategies.</p>
                                <!--<div class="banner-btn">
                                    <a routerLink="/contact" class="default-btn me-4">
                                        Get Started <span></span>
                                    </a>

                                    <a routerLink="/" class="default-btn-two">
                                        Try It Free <span></span>
                                    </a>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="ml-video">
                                <div class="solution-video">
                                    <a href="https://www.youtube.com/watch?v=lYCyPvKoJsA"
                                       class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>

    <div class="machine-learning-banner ml-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container mt-80">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="banner-content">
                                <h1>Creating Portable Magic in the form of Books.</h1>
                                <p>Books are the training weights of the mind. We aim to provide the best quality
                                    material, that is both, knowledgeable, engaging and attractive.</p>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="ml-video">
                                <div class="solution-video">
                                    <a href="https://www.youtube.com/watch?v=B6LLjBVoljI"
                                       class="video-btn popup-youtube">
                                        <i class="flaticon-play-button"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Shape Images -->
        <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
        <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
        <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Solutions Area -->
<!--<section class="pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">What We Offer</span>
            <h2>Our Featured Solutions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bxs-analyse'></i>
                    <h3>
                        <a routerLink="/service-details">Robotic Automation</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bx-shape-circle'></i>
                    <h3>
                        <a routerLink="/service-details">Cognitive Automation</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bx-badge-check'></i>
                    <h3>
                        <a routerLink="/service-details">Security & Surveillance</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="featured-solution-card">
                    <i class='bx bx-layer'></i>
                    <h3>
                        <a routerLink="/service-details">Cognitive Engagement</a>
                    </h3>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <a routerLink="/service-details" class="read-more">Discover More</a>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Featured Solutions Area -->

<!-- Start Featured Services Area -->
<!--<section class="featured-services-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2>Our Featured Services</h2>
            <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon">
                        <i class="fa-solid fa-building-columns"></i>
                    </div>
                    <h3>Education</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore</p>
                    <a routerLink="/service-details" class="default-btn">Read More <span></span></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon color-facd60">
                        <i class="fa-solid fa-suitcase-medical"></i>
                    </div>
                    <h3>Health Care</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore</p>
                    <a routerLink="/service-details" class="default-btn">Read More <span></span></a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-box">
                    <div class="icon color-1ac0c6">
                        <i class="fa-solid fa-newspaper"></i>
                    </div>
                    <h3>Media & Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore</p>
                    <a routerLink="/service-details" class="default-btn">Read More <span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Featured Services Area -->

<!-- Start Partner Area -->

<div class="partner-area ptb-100 pt-0  bg-454545">
    <div class="container-fluid  pt-4">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="">
                <a href="https://msinstituteindia.com" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/ms_institute.png" alt="MS Institute"
                         title="MS Institute">
                    <!--    <img class=w-75 src="assets/img/partner-image/ms-institute-grey.png" alt="MS Institute"
                             title="MS Institute">-->
                </a>
            </div>
            <div class="">
                <a href="https://www.educationmitra.in" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/education_mitra.png"
                         alt="Education Mitra"
                         title="Education Mitra">
                    <!--   <img class=w-75 src="assets/img/partner-image/education-mitra-grey.png" alt="Education Mitra"
                            title="Education Mitra">-->
                </a>
            </div>
            <div class="">
                <a href="https://www.edutantra.in" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/edutantra.png" alt="Edutantra"
                         title="Edutantra">
                    <!-- <img class=w-75 src="assets/img/partner-image/edutantra-grey.png" alt="Edutantra" title="Edutantra">-->
                </a>
            </div>
            <div class="">
                <a href="https://draxico.com" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/dr_axico.png" alt="Dr Axico"
                         title="Dr Axico">
                    <!--<img class=w-75 src="assets/img/partner-image/dr-axico-grey.png" alt="Dr Axico" title="Dr Axico">-->
                </a>
            </div>

            <div class="">
                <a href="https://axicoayurveda.com" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/axico_ayurveda.png" alt="Axico Ayurveda"
                         title="Axico Ayurveda">
                </a>
            </div>
            <div class="">
                <a href="https://nationliveiptv.com" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/nation_live.png" alt="Nation Live"
                         title="Nation Live">
                    <!--   <img class=w-75 src="assets/img/partner-image/nation-live-grey.png" alt="Nation Live" title="Nation Live">-->
                </a>
            </div>
            <div class="">
                <a href="https://www.sbprakashan.com/" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/sb_prakashan.png" alt="S.B Prakashan"
                         title="S.B Prakashan">
                </a>
            </div>
            <div class="">
                <a href="https://seasfoundation.com/" target="_blank" style="padding: 0 !important;">
                    <img class="w-75" src="assets/img/Child%20Companies%20card/seas.png" alt="Seas Foundation"
                         title="Seas Foundation">
                </a>
            </div>
        </div>
    </div>
</div>

<!-- End Partner Area -->

<!-- Ml Services Area -->
<section class="ml-services-area pt-100 pb-70 ">
    <div class="container-fluid" style="width: 98%;">
        <div class="section-title text-center ps-2">
            <h2>Our Professionals <span class="cl-blue">Services For You</span></h2>
            <p class="py-2" style="font-size: 1.25rem">We commit to deliver broad range of services you can trust upon,
                through our diversified array of
                ventures.</p>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="ml-service background-color-azure rounded-10 shadow">
                    <div class="image text-center">
                        <img src="assets/img/machine-learning/ml-service1.png" alt="image">
                    </div>
                    <h3 class="text-start"><a routerLink="/education-services">Education</a></h3>
                    <p class="text-muted my-2" style="text-align: justify;">Spreading knowledge since 1997. With the
                        advancement of education and
                        technology, we are
                        successfully working in education sector and providing comprehensive...</p>
                    <button class="btn btn-light pt-2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"><a
                        routerLink="/education-services" class="read-more new-btn-25252">
                        Know More &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
                    </a></button>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="ml-service background-color-azure rounded-10 shadow">
                    <div class="image text-center">
                        <img src="assets/img/machine-learning/ml-service3.png" alt="image">
                    </div>
                    <h3 class="text-start"><a routerLink="/healthcare-services">Health & Manufacturing</a></h3>
                    <p class="text-muted my-2" style="text-align: justify">We provide health care services and creating
                        array
                        of opportunities for
                        well-being with latest
                        technology through integrated primary healthcare products...</p>
                    <!--<a routerLink="/healthcare-services" class="read-more">
                        Read More
                    </a>-->
                    <button class="btn btn-light pt-2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"><a
                        routerLink="/healthcare-services" class="read-more new-btn-25252">
                        Know More &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
                    </a></button>
                </div>
            </div>
            <!--<div class="col-lg-2 col-md-6"></div>-->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="ml-service background-color-azure rounded-10 shadow">
                    <div class="image text-center">
                        <img src="assets/img/machine-learning/ml-service4.png" alt="image">
                    </div>
                    <h3 class="text-start "><a routerLink="/publishing-house-services">Publishing House</a></h3>
                    <p class="text-muted my-2" style="text-align: justify">Our Publishing house publishes standardized
                        and
                        quality content textbooks for
                        the students of
                        class KG to 8th and study learning materials for...</p>
                    <button class="btn btn-light pt-2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"><a
                        routerLink="/publishing-house-services" class="read-more new-btn-25252">
                        Know More &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
                    </a></button>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="ml-service background-color-azure rounded-10 shadow">
                    <div class="image text-center">
                        <img src="assets/img/machine-learning/ml-service5.png" alt="image">
                    </div>
                    <h3 class="text-start"><a routerLink="/media-and-marketing-services">Media & Marketing</a></h3>
                    <p class="text-muted my-2" style="text-align: justify">This is one of our sustained effort to
                        educate
                        individuals and boost public
                        awareness about
                        social-political reforms through most effective .....</p>
                    <button class="btn btn-light pt-2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"><a
                        routerLink="/media-and-marketing-services" class="read-more new-btn-25252">
                        Know More &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
                    </a></button>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Ml Services Area -->


<!-- About Us -->
<section class="choose-area-two pb-100 ">
    <div class="container-fluid mt-5 pt-2">
        <div class="row  ">
            <div class="col-lg-6 col-md-12">
                <div class="choose-image text-center">
                    <img src="assets/img/machine-learning/about3.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 mt-5">
                <div class="choose-content ">
                    <div class="section-title text-start">
                        <h2><span style="font-size: 36px;">About <span
                            style="color: rgba(33, 150, 243, 1);">Us</span></span></h2>
                        <p style="display: block">MS Group has established its name over years by
                            building trust through our constant <br> dedication and brand management.</p>
                        <br>
                        <span class="d-flex"><img src="assets/img/about/about_1.png" class="me-5" alt=""> <img
                            src="assets/img/about/about_2.png" alt=""></span>
                        <p class="py-3">Engaging New Audiences with the help of strong IT foundation and our dedicated
                            team
                            efforts.</p>
                        <p>MS Group is one of the leading companies when it comes to the education sector. Founded in
                            year <br> 1997. With our expansions in the fields like health care, print and electronic
                            media,
                            we have built <br> various trusted brands.</p>
                    </div>

                    <div class="choose-btn my-5">
                        <button class="btn btn-light pt-2 " style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"><a
                            routerLink="/about" class="read-more new-btn-25252">
                            Know More &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
                        </a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Us -->

<!-- Start Projects Area -->
<section class="projects-area ptb-100">
    <div class="container">
        <div class="section-title  text-center">
            <h2>Our <span class="cl-blue">Products</span></h2>
            <p>Discover excellence with "Our Products and Services." Elevate your experience with our premium services
                in the field of education, printing, health, and media, designed to exceed your expectations. Quality
                redefined.</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="projects-slides owl-carousel owl-theme">
            <div class="">
                <a class="our-service-link" href="https://msinstituteindia.com/" target="_blank"> <img src="assets/img/projects-image/products/ms_institute.png" alt="image"></a>
                <!--<div class="plus-icon">
                    class=pt-2 <a href="assets/img/projects-image/projects1.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>-->
            </div>
            <div class=" rounded-10 ">
                <a class="our-service-link" href="https://educationmitra.in/" target="_blank"> <img src="assets/img/projects-image/products/education_mitra.png" alt="image"></a>

            </div>

            <div class=" rounded-10 ">
                <a class="our-service-link" href="https://edutantra.in/" target="_blank">  <img src="assets/img/projects-image/products/edutantra.png" alt="image"></a>

            </div>


            <div class=" rounded-10 ">
                <a class="our-service-link" href="https://draxico.com/" target="_blank"><img src="assets/img/projects-image/products/dr_axico.png" alt="image"></a>

            </div>
            <!--<div class=" rounded-10 shadow ">
                <img src="assets/img/projects-image/projects6.jpg" alt="image">

                <div class="projects-content shadow-lg p-3 mb-5 bg-body rounded bg-opacity-50">
                     <div class="rounded-10 shadow" style="background: #FFFFFF">
                    <h3 class=pt-2><a style="color: #ee3339;" routerLink="https://www.axicohealthcare.com/">Axico Healthcare</a></h3>
                    <span class="category">Healthcare</span>
                    </div>
                </div>

                <div class="plus-icon">
                    class=pt-2 <a href="assets/img/projects-image/projects6.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>-->
            <div class=" rounded-10 ">
                <a class="our-service-link" href="https://axicoinstitute.com/" target="_blank">  <img src="assets/img/projects-image/products/axico_institute.png" alt="image"></a>
            </div>
            <!-- <div class=" rounded-10 shadow ">
                 <img src="assets/img/projects-image/projects4.png" alt="image">
                 <div class="projects-content shadow-lg p-3 mb-5 bg-body rounded bg-opacity-50">
                      <div class="rounded-10 shadow" style="background: #FFFFFF">
                     <h3 class=pt-2><a style="color: #ee3339;" href="https://axicoinstitute.com/" target="_blank">Axico
                         Institute</a></h3>
                         </div>
                     <span class="category">Education</span>
                 </div>
             </div>-->
            <div class=" rounded-10 ">
                <a  class="our-service-link" href="https://www.nationliveiptv.com/" target="_blank"> <img src="assets/img/projects-image/products/news_nation.png" alt="image"></a>
            </div>
            <!--<div class=" rounded-10 shadow ">
                <img src="assets/img/projects-image/projects6.jpg" alt="image">
                <div class="projects-content shadow-lg p-3 mb-5 bg-body rounded bg-opacity-50">
                     <div class="rounded-10 shadow" style="background: #FFFFFF">
                    <h3 class=pt-2><a style="color: #ee3339;" href="https://nationliveiptv.com/" target="_blank">Nation Live</a></h3>
                    <span class="category">Media & Marketing</span>
                    </div>
                </div>
            </div>-->
            <div class=" rounded-10  ">
                <a class="our-service-link" href="https://www.sbprakashan.com/" target="_blank" >      <img src="assets/img/projects-image/products/sb_prakashan.png" alt="image"></a>

            </div>
            <!--<div class=" rounded-10 shadow cursor-pointer">
                <img src="assets/img/projects-image/projects6.jpg" alt="image">
                <div class="projects-content shadow-lg p-3 mb-5 bg-body rounded bg-opacity-50">
                    <h3><a style="color: #ee3339;" href="https://seasfoundation.com/" target="_blank">Seas Foundation</a></h3>
                    <span class="category">Education</span>
                </div>
                <div class="plus-icon">
                    <a href="assets/img/projects-image/projects6.jpg" class="popup-btn">
                        <span></span>
                    </a>
                </div>
            </div>-->
        </div>
    </div>
</section>
<!-- End Projects Area -->


<!-- Start Fun Facts Area -->
<section class="fun-facts-area ptb-80">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex ">
                    <img src="assets/img/funfact/stats_projects_icon.png" alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="20">00</span><i
                            class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Products</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex">
                    <img src="assets/img/funfact/stats_clients_icons.png" style="height: 50%" alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="1000">00</span><i
                            class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Happy Clients</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex">
                    <img src="assets/img/funfact/stats_awards_icon.png" alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="10">00</span><i
                            class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Awards</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-facts d-flex">
                    <img src="assets/img/funfact/stats_expertmember_icons.png" style="height: 50%" alt="">
                    <div>
                        <h3 class="align-self-center ms-4"><span class="odometer" data-count="200">00</span><i
                            class="fa-solid fa-plus fa-xs"></i></h3>
                        <p class="text-center ps-4">Expert Members</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts Area -->


<!-- Start Solution Area -->
<!--<section class="solution-area ptb-100 extra-pb jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <span class="sub-title">Watch Our Video</span>
                    <h2>Making world a better place</h2>
                    &lt;!&ndash;<p>No fake products and services. The customer is king, their lives and needs are the inspiration.
                    </p>&ndash;&gt;
                    <a routerLink="/contact" class="default-btn">Contact Us <span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solution-video">
                    <a href="https://www.youtube.com/watch?v=npYoAwQc4_k" class="video-btn popup-youtube"><i
                        class="flaticon-play-button"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Solution Area -->

<!-- Start Feedback Area -->
<section class="feedback-area ptb-35 mt-3 bg-color">
    <div class="container">
        <div class="section-title text-center">
            <h2>Some Feedback From Our Clients</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item rounded-10">
            <p>“I am a patient of Axico Ayurveda taking treatment from respective doctor since a year. I am suffering
                from diabetes. With Ayurvedic diet, medicines & yoga practice I felt much better after 4 months & the
                treatment continued for a year. Thanks to Dr. Axico and yoga, my life changed a lot. Now, my doctor will
                probably lower the doses.”</p>
            <div class="client-info p-0">
                <div class="d-flex justify-content-between">
                    <h3>Manish Sharma</h3>
                    <div>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                </div>
                <span>Dr. Axico</span>
            </div>
        </div>

        <div class="single-feedback-item rounded-10">
            <p>“Hi,earlier I had a lot of acne on my skin. I suffered from this condition for 5 years. I was feeling
                under confident because of these skin issues. But then I started using Axico Ayurveda Products and took
                proper Ayurvedic treatment for my skin. Now, I feel more confident and my skin feels so good. Thank you
                so much Axico Ayurveda.”</p>
            <div class="client-info p-0">
                <div class="d-flex justify-content-between">
                    <h3>Shurti Pathak</h3>
                    <div>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                </div>
                <span>Axico Ayurveda</span>
            </div>
        </div>

        <div class="single-feedback-item rounded-10">
            <p>“Education Mitra has helped me to become more informed and take meaningful career decisions after the
                guidance offered by their skilled counsellors. Education Mitra empowered me and provided me with the
                endless array of possibilities and right opportunity to pursue online and distance education.”</p>
            <div class="client-info p-0">
                <div class="d-flex justify-content-between">
                    <h3>Neelam Sinha</h3>
                    <div>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                </div>
                <span>Education Mitra</span>
            </div>
        </div>

        <div class="single-feedback-item rounded-10">
            <p>“Taking a course from Edutantra allowed me to plan your schedule according to your busy life without
                sacrificing quality education. Taking classes online is wonderful for people who are busy and unable to
                attend courses in person. But Edutantra made it easy for me to pursue online
                education along with the work.”</p>
            <div class="client-info p-0">
                <div class="d-flex justify-content-between">
                    <h3>Veena Mehra</h3>
                    <div>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                </div>
                <span>Edutantra</span>
            </div>
        </div>

        <div class="single-feedback-item rounded-10">
            <p>“Jan Media news portal is amongst the favourite choice of news reader. The channel is equipped with news
                of all sorts. The Portal has a great TRP.”
                <br><br><br><br><br>
            </p>
            <div class="client-info p-0">
                <div class="d-flex justify-content-between">
                    <h3>Diwakar Chowdhary</h3>
                    <div>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star star-fill"></i>
                        <i class="fa-solid fa-star"></i>
                    </div>
                </div>
                <span>Jan Media</span>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start FAQ Area -->
<section class="faq-area  ">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center">Frequently Asked <span class="cl-blue">Questions</span></h2>
        </div>

        <div class="row align-items-center">

            <div class="col-lg-12">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" style="color: rgba(102, 112, 133, 1);" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                    aria-controls="collapseOne">
                                How do the students who wish to pursue higher education through online / distance
                                education know the recognition status of Institution ?
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p class="accordion-content">The year-wise recognition status of universities approved
                                    to
                                    offer education through online/distance mode along with the approved programmes is
                                    already in public domain and accessible via UGC website <span
                                        (click)="goTo('http://www.ugc.ac.in/deb')"
                                        class="text-primary cursor-pointer"> www.ugc.ac.in/deb</span>. The same can
                                    be accessed through website at the following link: <span
                                        (click)="goTo('http://www.ugc.ac.in/deb/notices.html')"
                                        class="text-primary cursor-pointer">www.ugc.ac.in/deb/notices.html</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" style="color: rgba(102, 112, 133, 1);"
                                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">
                                How to book an appointment on Dr. Axico ?
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p class="accordion-content">You can easily book an appointment through our app and
                                    website
                                    <span (click)="goTo('http://www.ugc.ac.in/deb/notices.html')"
                                          class="text-primary cursor-pointer">www.draxico.in</span>
                                    by filling your general details and symptoms you are facing and by paying
                                    the registration fees.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" style="color: rgba(102, 112, 133, 1);"
                                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                    aria-expanded="false" aria-controls="collapseThree">
                                What is the delivery timeline of my order on Dr. Axico ?
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p class="accordion-content">The general delivery timeline is 3 to 7 business days
                                    (metro
                                    cities) and 15 to 21 business days (non-metro cities) depending on the delivery
                                    address
                                    pin code. We deliver across the PAN India.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" style="color: rgba(102, 112, 133, 1);"
                                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                    aria-expanded="false" aria-controls="collapseFour">
                                What is the Registration Procedure of Education Mitra ?
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p class="accordion-content">For the Students to get latest updates and details , they
                                    have
                                    to register themselves on our website <span class="text-primary cursor-pointer"
                                                                                (click)="goTo('https://www.educationmitra.in')">www.educationmitra.in</span>
                                    with their details( Full name, Email, Contact etc…)</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" style="color: rgba(102, 112, 133, 1);"
                                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                    aria-expanded="false" aria-controls="collapseFive">
                                What are the benefits of Education Mitra ?
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p class="accordion-content">Education Mitra helps students to become more informed and
                                    take
                                    meaningful career decisions after the guidance offered by our skilled counsellors.
                                    Education Mitra will empower students and provide them with an endless array of
                                    possibilities and right opportunities to pursue online and distance education.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center">Recent <span class="cl-blue">Blogs</span></h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                 dolore magna aliqua.</p>-->
        </div>


        <div class="row" style="padding: 20px;">
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card mb-3 " style="border: none;padding: 20px;">
                    <div class="card-header h-75" style="padding: 0px;">
                        <a href="blogs/blog-benefits-of-ayurvedic-treatments-and-lifestyles">
                            <img src="assets/img/blog-image/blog2.jpg" alt="" class="blog-zoom">
                        </a>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <a href="blogs/blog-benefits-of-ayurvedic-treatments-and-lifestyles">
                                    <h4>Benefits of Ayurvedic Treatments and Lifestyles</h4>
                                    <p class="max-lines">
                                        In general, Ayurveda is one of the oldest medications in the world. It is also
                                        the
                                        most
                                        effective
                                        treatment when compared to current modern treatments.
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="card mb-4" style="padding: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <a href="blogs/blog-convince-and-transform-lives-with-jan-media-component">
                                        <h4>Convince and Transform lives with Jan Media</h4>
                                    </a>
                                    <p class="max-lines">
                                        As an Indian conglomerate, Jan Media has shown itself in various other
                                        industries and the
                                        domains of health care, education, and publishing.
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 text-center">
                                <a href="blogs/blog-convince-and-transform-lives-with-jan-media-component">
                                    <img src="assets/img/blog-image/blog6.jpg" alt="" class="side-blog-img blog-zoom">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-4" style="padding: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <a href="blogs/blog-get-an-overview-of-dr-axico-health-campus">
                                        <h4>Get an overview of Dr. Axico Health campus</h4></a>
                                    <p class="max-lines">
                                        'Dr. Axico,' is a doctor-to-patient communication platform that allows patients
                                        to ask
                                        questions
                                        about their health and get answers from physicians.
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 text-center">
                                <a href="blogs/blog-get-an-overview-of-dr-axico-health-campus">
                                    <img src="assets/img/blog-image/blog12.jpg" alt="" class="side-blog-img blog-zoom"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" style="padding: 10px;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-8">
                                <div class="row">
                                    <a href="blogs/blog-effective-ayurvedic-therapies-for-health-and-longevity">
                                        <h4>Effective Ayurvedic Therapies for Health and</h4></a>
                                    <p class="max-lines">
                                        People often focus on ayurvedic therapies to boost health and wellness in the
                                        present scenario.
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 text-center">
                                <a href="blogs/blog-effective-ayurvedic-therapies-for-health-and-longevity">
                                    <img src="assets/img/blog-image/blog1.jpg" alt="" class="side-blog-img blog-zoom"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="choose-btn" style="text-align: center;">
        <!--        <a class="default-btn" routerLink="/blogs">-->
        <!--            See All Blogs-->
        <!--            <span></span>-->
        <!--        </a>-->
        <button class="btn btn-light pt-2" style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;"><a routerLink="/blogs"
                                                                                                    class="read-more new-btn-25252">
            See All Blogs &nbsp; <span><img src="assets/img/machine-learning/pseudo.png" alt=""></span>
        </a></button>
    </div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
</section>
<!-- End Blog Area -->
