<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2" style="background-image: url('assets/img/about/about-banner.png'); background-size: cover;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white;">Healthcare Services</h2>
                    <ul>
                        <li style="color: white;">Healthcare Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Healthcare Services</h3>
                <p>In Healthcare sector, we deal in Ayurveda medicines and our spectrum ranges to 50+
                    products. Our objective is to focus on the overall wellness of a person, and to make them aware
                    about the various preventive care ingredients present in the Ayurvedic products.</p>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Axico Ayurveda</h4>
                    <p>Axico Ayurveda is the name of the Ayurvedic Vertical of Axico HealthCare Pvt. Ltd. (2014).
                        Working since 2018 with a mission to become one of the best Health Solution provider in world.
                        Being a proud part of MS Group of Companies we are committed to work in society for noble causes
                        in a professional way. We always follow the guideline of our Founder & MD Mr. Pawan Singhal that
                        “doing things differently is more important than doing different things”.</p>
                </div>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Dr. Axico</h4>
                    <p>Dr. Axico is initiative for direct communication between specialist doctors and a patient,
                        committed to create a caring, inspiring & a gratifying environment for our patients and
                        partners. A dedicated 24x7 consultancy platform with highly qualified and experienced Doctors
                        and health advisors to guide and make available various health care and wellness products.</p>
                </div>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <!--<img src="assets/img/about-img1.png" alt="image">-->
                <img src="assets/img/MS%20Group%20Web%20Images3-min.png" alt="image" style="margin-left: 36px; max-width: 91%">
            </div>
        </div>

        <!--<div class="services-details-overview">
            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/why-choose-img1.png" alt="image">
            </div>
            <div class="services-details-desc">
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> S.B. Prakashan Pvt. Ltd.</h4>
                    <p>Welcome to the World of Book Publishing!! <br>
                        A leading name in higher education publishing, specializes in the publication of academic and
                        reference books in various Online / Distance Undergraduate / Postgraduate learning programs. We
                        also work as a publishing house for various school textbooks and presently deal with content
                        related to classes 1st to 8th.</p>
                </div>
                <div class="features-text">
                    <h4><i class="flaticon-tick"></i> Education Mitra</h4>
                    <p>Education Mitra is the dedicated advanced user-friendly search engine platform that provides a
                        comprehensive database of various Open and Distance Learning / Online Learning
                        Undergraduate / Postgraduate programs, free online guidance, and counselling sessions (one to
                        one
                        live), and all the information regarding all the Higher Education Institutions which are
                        entitled to start Online / Distance programs from UGC.</p>
                </div>
            </div>
        </div>-->
    </div>
</section>
<!-- End Services Details Area -->
