<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2"
     style="background-image: url('assets/img/about/about-banner.png'); background-size: cover">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2 style="color: white; margin-top: -4%">Privacy Policy</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->
<section class="py-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <div class="card shadow" style="border: none!important;">
                    <div class="card-body">
                        <p>Welcome to MS Group of Companies’ privacy policy. While visiting or using our
                            website, your personal information is collected, used, and disclosed following our
                            Privacy Policy. For more information on how we manage your personal information,
                            please read our policy carefully.</p>
                        <div class="features-text">
                            <h5> Information We Gather</h5>
                            <ol type="a">
                                <li class="color-for-list">
                                    <strong>Personal Information:</strong> When you fill out forms on our website, sign
                                    up for our
                                    newsletter, use the features and services of our website, or otherwise interact
                                    with it, we may collect personal information from you, such as your name, email
                                    address, postal address, phone number, and any other details you freely
                                    provided to us.
                                </li>
                                <li class="color-for-list">
                                    <strong>Automatically Collected Information:</strong> We may automatically gather certain
                                    data about your device and usage habits when you visit our website. This data
                                    may   include   things   like   your   IP   address,   operating   system,   browser   type,
                                    referring websites, and the web pages you view on our website.
                                </li>
                            </ol>

                            <div class="features-text">
                                <h5>Use of Information</h5>
                                <p>We may use the information for below-mentioned purposes:</p>
                                <ul class="features-text">
                                    <li class="color-for-list">
                                        To enhance our website's services, features, and content.
                                    </li>
                                    <li class="color-for-list">
                                        To promptly respond to your comments, inquiries, or questions.
                                    </li>
                                    <li class="color-for-list">
                                        To   share   updates,   newsletters,   marketing   communications,   and   other
                                        information related to our services.
                                    </li>
                                    <li class="color-for-list">
                                        To monitor and analyze usage patterns and trends on our portal.
                                    </li>
                                    <li class="color-for-list">
                                        To protect the security and integrity of our website and prevent fraud.
                                    </li>
                                    <li class="color-for-list">
                                        To comply with legal obligations or as otherwise required by law.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="features-text">
                            <h5> Cookies and Tracking Technologies</h5>
                            <p>Cookies and other tracking technologies may be used on our website to improve
                                user experience and track visitor traffic. We can recognize your preferences, learn
                                more about how you interact with our website, and remember certain information
                                thanks to cookies, which are little files that are placed on your device's browser. You
                                can limit or even disapprove the cookies as well.</p>
                        </div>
                        <div class="features-text">
                            <h5> Disclosure of Information</h5>
                            <p>We may disclose your personal information to: </p>
                            <ul>
                                <li class="color-for-list">
                                    Third-party service providers who assist us in providing website services,
                                    subject to contractual obligations to maintain the confidentiality and security
                                    of your information.
                                </li>
                                <li class="color-for-list">
                                    In connection with any merger, sale, acquisition, or restructuring of our
                                    company, in which case your personal information may be transferred as part
                                    of the transaction.
                                </li>
                                <li class="color-for-list">
                                    Law   enforcement   agencies,   government   authorities,   or   legal   entities,   as
                                    required by applicable laws, regulations, or court orders.
                                </li>
                            </ul>
                        </div>
                        <div class="features-text">
                            <h5>Third-Party Links</h5>
                            <p>Links to other websites may be found on our website. We are not liable for the
                                privacy policies or content of such websites. It is advisable to read the privacy policy
                                and terms and conditions on your own before providing any personal information to
                                the website/external links.</p>
                        </div>
                        <div class="features-text">
                            <h5>Data Security</h5>
                            <p>To prevent unauthorized access, disclosure, or change of your personal information,
                                we use industry-standard security measures. A totally secure method of electronic
                                storage or data transmission over the Internet does not exist, though. We make
                                every possible effort to safeguard your personal information. </p>
                        </div>
                        <div class="features-text">
                            <h5>Changes to Privacy Policy</h5>
                            <p>The MS Group has the freedom to change this privacy statement whenever it wants.
                                The "Last updated" date at the top of this policy will be updated to reflect the most
                                recent change. You agree to the updated terms by using our website after any
                                changes have been made to this privacy statement.</p>

                        </div>
                        <div class="features-text">
                            <h5>Contact Us</h5>
                            <p>
                                If you have any questions or concerns about these Terms & Conditions, please
                                contact us at <a href="mailto:hr@msgroupindia.com">hr@msgroupindia.com</a>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
